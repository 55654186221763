import { Component, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AlertCommitCalenderComponent } from '../alert-commit-calender/alert-commit-calender.component';
import { AlertWebCalendarComponent } from '../alert-web-calendar/alert-web-calendar.component';
import { AppState } from '../app.service';
import { CalendarService } from '../calendar.service';
import { CordovaService } from '../cordova.service';
import { CalendarParams } from '../onboarding-survey/onboarding-survey.component';
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { ToasterService } from '../toaster.service';
import { RoutineV2Service } from './routine-v2.service';
import { I18n } from '../i18n.service';
import { ModalController, PickerController } from '@ionic/angular';
import { HomeUiService } from '../new-design/home-ui.service';

@Component({
  selector: 'app-weekend-calendar',
  templateUrl: './weekend-calendar.component.html',
  styleUrls: ['./weekend-calendar.component.scss'],
})
export class WeekendCalendarComponent implements OnInit {


  calendarParams: CalendarParams
  recurrenceInterval: string;
  hasNativeCalendar: any;
  currentPath: any;
  selectedDay:any
  currentRoutine:any
  isSingleEvent:boolean = false
  minDate: any;
  maxDate: any;
  timings: string[];
  routineTime: any;
  selectedDate: any;
  backTo: string;
  @ViewChild('matTime') matTime: MatSelect;
  @ViewChild('matDate', {
    read: MatInput
  }) matDate: MatInput;
  dayNameArray=[];
  todayTimeArray=[];
  isEventCreated: boolean
  routineDay: any
  singleRoutineTime: any
  cueTime: string
  newDayTimeArray: any
  isTimeEdited: boolean
  priorTimeData: any
  combinedArray = []
  cueName: any
  isMobile: boolean
  isNewTimeSelected: boolean = false
  oldDayArray: any
  oldTimeArray: any
  isRoutineCalender: any
  selectedTimings: any
  selectedDays: any 
  optionType= 'option1' 
  routinAnswer: any
  routineTitle: any
  selectedDatesArray = []
  defaultTime: string = "07:00";
  constructor(private appState: AppState,private cordovaService: CordovaService,
    private router: Router,
    private dialog: MatDialog,
    private routinev2Service: RoutineV2Service,
      private homeService: HomeV5Service,
      private calendarService: CalendarService,
    //   private _location: Location,
      public route: ActivatedRoute,
      private toastrService: ToasterService,
      private i18n: I18n,
      private pickerController: PickerController,
      private modalController: ModalController,
      private homeUIService: HomeUiService,
       @Optional() public dialogRef: MatDialogRef<WeekendCalendarComponent>){

        const w: any = window
    this.hasNativeCalendar = this.cordovaService.onCordova && w && w.plugins && w.plugins.calendar
    this.currentPath = this.appState.get('currentPath')
    this.currentRoutine = this.appState.get("routineCalendarData")
    this.isSingleEvent = this.currentRoutine.isSingleEvent
    this.isTimeEdited = this.appState.get('isTimeEdited')
    this.isMobile = this.appState.get('isMobile')
    this.isRoutineCalender = this.appState.get('isRoutineCalender')
    this.selectedTimings = this.currentRoutine.routineTime
    this.selectedDays = this.currentRoutine.selectedDays
    this.routinAnswer = this.currentRoutine.routineAnswer
    this.routineTitle = this.currentRoutine.title
    this.timings = this.routinev2Service.TIMINGS_30_MINUTE
    const currentTime = new Date();
    currentTime.setHours(currentTime.getHours() + 1);
    const hours = currentTime.getHours(); // 0-23
    const minutes = currentTime.getMinutes(); // 0-59
     this.defaultTime  = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    if(this.isTimeEdited){
      this.isNewTimeSelected = false
    }

    this.oldDayArray = this.appState.get('differentDays')
    this.oldTimeArray = this.appState.get('differentTimes')

    if (this.oldDayArray && this.oldTimeArray) {
      this.dayNameArray = this.oldDayArray
      this.todayTimeArray = this.oldTimeArray
    }

    if (this.isRoutineCalender) {
      if (this.currentRoutine.level.routine_type === "Activity"){
        if (this.currentRoutine.level.activity_time) {
          this.cueName = this.currentRoutine.level.activity_time + " I " + this.currentRoutine.level.type_value
        }else{
          this.cueName = " before I " + this.currentRoutine.level.type_value
        }
      }
      if (this.currentRoutine.level.routine_type === "Person") {
        this.cueName = " when I encounter " + this.currentRoutine.level.type_value 
      }
      if (this.currentRoutine.level.routine_type === "Location") {
        this.cueName = " when I am " + this.currentRoutine.level.type_value 
      }
      if (this.currentRoutine.level.routine_type === "Other") {
        this.cueName = " when " + this.currentRoutine.level.type_value 
      }
      
    }else{
      if (this.currentRoutine.selectedCueOption === "Activity") {
        if (this.currentRoutine.activityCueTime) {
          this.cueName = this.currentRoutine.activityCueTime + " I " + this.currentRoutine.typeValue
        }else{
          this.cueName = " before I " + this.currentRoutine.typeValue
        }
      }
      if (this.currentRoutine.selectedCueOption === "Person") {
        this.cueName = " when I encounter " + this.currentRoutine.typeValue 
      }
      if (this.currentRoutine.selectedCueOption === "Location") {
        this.cueName = " when I am " + this.currentRoutine.typeValue 
      }
      if (this.currentRoutine.selectedCueOption === "Other") {
        this.cueName = " when " + this.currentRoutine.typeValue 
      }
    }
    
    this.newDayTimeArray = this.appState.get('dayTimeArray')    
    this.priorTimeData = this.appState.get('differentTimes')

    }

  ngOnInit() {
    this.currentRoutine = this.appState.get("routineCalendarData")
  }


  calenderCommit(){
    this.showAlertCommit(0);   
}

  differentTimesDays() {
    this.isSingleEvent = true;
  }
showAlertCommit(counter:any) {
  
    const dialogCommit = this.dialog.open(AlertCommitCalenderComponent, {
      data: {
        dataKey: { title: this.routineTitle,
                   time: this.selectedTimings[counter],
                   day: this.selectedDays[counter] 
                 } // day and time and title 
      }  // after closed setCalenderEvent 
    })
  
    dialogCommit.afterClosed().subscribe((result) => {

         

        if (this.currentRoutine.level.isSingleEvent) {
            this.setCalendarEventCommit(this.currentRoutine,
                this.selectedTimings, 
                this.currentRoutine.level.routine_date, 
                false, 
                counter)
        }else{
            this.setCalendarEventCommit(this.currentRoutine,
                this.selectedTimings[counter], 
                this.currentRoutine.level.routine_date[counter], 
                false, 
                counter)
        }
      
    })
}

setCalendarEventCommit(currentRoutine:any,routineTime:any,selectedDate: any=null, isTimeEvent: boolean, counter:any){
    this.appState.set('isTimeEdited', false)
    var routineLen = 1
  
    if(!this.isSingleEvent){
      routineLen=7
    }

    if (!selectedDate) {
        selectedDate = moment()
    }
  
    if(!currentRoutine.routineAnswer){
      currentRoutine.routineAnswer=""
    }
  
    if(this.hasNativeCalendar){
      this.prepareRoutineCalendarParams("1st90 Habit: "+currentRoutine.title,currentRoutine.routineAnswer  +" "+ this.routinev2Service.innerHtmlToRoutinesData(currentRoutine.text),routineLen,'Daily',routineTime,selectedDate)
      if(this.isSingleEvent){
        this.createDifferentSubRoutineEvent()
      }else{
        this.createSubRoutineEvent()
      }

        if (counter < this.selectedTimings.length - 1) {
            this.showAlertCommit(counter + 1);
        } else {
            this.dialogRef.close()
        }
      
    }else {
      console.log('currentRoutine', currentRoutine);
      
      this.appState.set('isEventCreated', true)
      this.prepareRoutineCalendarParams("1st90 Habit: "+currentRoutine.title,currentRoutine.routineAnswer +" "+ this.routinev2Service.innerHtmlToRoutinesData(currentRoutine.text),routineLen,'Daily',routineTime,selectedDate)
      this.calendarParams.notes = currentRoutine.routineAnswer + this.routinev2Service.innerHtmlToRoutinesData(currentRoutine.text)
      this.homeService.setCalendarData(this.calendarParams)
                  
      const dialogRef = this.dialog.open(AlertWebCalendarComponent) 
        dialogRef.afterClosed().subscribe((result) => {

            if (counter < this.selectedTimings.length - 1) {
                this.showAlertCommit(counter+1);
            }else{
                this.dialogRef.close()
            }
        
          
        })
    }
  }


  prepareRoutineCalendarParams(title:any,msg: String,routinelen:any = null,eventlen:string =null, routineTime: any=null,selectedDate:any=null) {
    var date = moment().format('LL')

    if (!routineTime) {
      this.toastrService.error(this.i18n.t("Please select routine time!"))
    }

    if(selectedDate){
     var date = moment(selectedDate).format('LL')
    }

    console.log("Selected msg msg",msg);
    

      var eventDate = date + " " + routineTime
      const start = moment(eventDate)
      const end = moment(eventDate).add(10, 'minute')
      var  titleOfEvent = "Daily Learning Reminder"

      if(this.currentPath.names && this.currentPath.names.titleOfEvent){
        titleOfEvent = this.currentPath.names.titleOfEvent;
      }else{
        titleOfEvent = "Daily Learning Reminder"
      }  

      this.calendarParams = {
        startDate: start.toDate(),
        endDate: end.toDate(),
        title: title, //updated title
        eventLocation: '',
        notes: msg,
        endEventTime: routinelen,
        frequency: eventlen
      }
  }

  
  createSubRoutineEvent() {
    const success = function(message) {
      
      console.log('Success routine calendar: ' + JSON.stringify(message))
      
    }
    const error = function(message) { console.error('Error: ' + message) }
    this.recurrenceInterval='never'

    const w: any = window
    const calOptions = w.plugins.calendar.getCalendarOptions()
    const calendarList = w.plugins.calendar.listCalendars(success,error);

    console.log("Calendar List ",calendarList);
    
    const daysCount = 7  // LUMBA-1399
    calOptions.recurrence = this.recurrenceInterval.toLowerCase(); // supported are: daily, weekly, monthly, yearly
    calOptions.firstReminderMinutes = 0
    calOptions.recurrenceEndDate = moment(new Date(), 'HH:mm').add(daysCount, 'day').toDate()
    // w.plugins.calendar.createEventInteractivelyWithOptions(
    //   this.calendarParams.title,
    //   this.calendarParams.eventLocation,
    //   this.calendarParams.notes,
    //   this.calendarParams.startDate,
    //   this.calendarParams.endDate,
    //   calOptions,
    //   success,
    //   error
    // )

    this.calendarService.scheduleEvents(
      this.calendarParams.title,
      this.calendarParams.eventLocation,
      this.calendarParams.notes,
      this.calendarParams.startDate,
       this.calendarParams.endDate,
       calOptions,
       success,
       error
      );
  }

  createDifferentSubRoutineEvent() {
    const success = function(message) {
      
      console.log('Success routine calendar: ' + JSON.stringify(message))
      
    }
    const error = function(message) { console.error('Error: ' + message) }
    //this.recurrenceInterval='never'

    const w: any = window
    const calOptions = w.plugins.calendar.getCalendarOptions()
    const calendarList = w.plugins.calendar.listCalendars(success,error);

    console.log("Calendar List ",calendarList);
    
    const daysCount = 7  // LUMBA-1399
    //calOptions.recurrence = this.recurrenceInterval.toLowerCase(); // supported are: daily, weekly, monthly, yearly
    calOptions.firstReminderMinutes = 0
    //calOptions.recurrenceEndDate = moment(new Date(), 'HH:mm').add(daysCount, 'day').toDate()

    this.calendarService.scheduleEvents(
      this.calendarParams.title,
      this.calendarParams.eventLocation,
      this.calendarParams.notes,
      this.calendarParams.startDate,
       this.calendarParams.endDate,
       calOptions,
       success,
       error
      );
}

  close(){
    this.appState.set('isTimeEdited', false)
    this.dialogRef.close()    
    }

  async openPicker(
    time
   ) {
     
     console.log("everyday at",new Date().getHours(),  this.defaultTime);
    let defaultHour = parseInt(this.defaultTime.split(":")[0]);
    if (time) {
      defaultHour = parseInt(time.split(":")[0]);
    }
    const defaultMinute = parseInt(
      this.defaultTime.split(":")[1].split(" ")[0]
    );
    let defaultAmPm = this.defaultTime.split(" ")[1];
    defaultAmPm = defaultHour < 12 ? "AM" : "PM";

    if (defaultHour === 0) {
      defaultHour = 12;
    } else if (defaultHour > 12) {
      defaultHour -= 12;
      defaultAmPm = "PM";
    }
    const picker = await this.pickerController.create({
      columns: [
        {
          name: "hour",
          options: this.generateHourOptions(), // For hours in 12-hour format
          selectedIndex: defaultHour - 1,
        },
        {
          name: "minute",
          options: this.generateColumnOptions(), // For minutes
          selectedIndex: defaultMinute === 0 ? 0 : 1,
        },
        {
          name: "ampm",
          options: [
            { text: "AM", value: "AM" },
            { text: "PM", value: "PM" },
          ],
          selectedIndex: defaultAmPm === "AM" ? 0 : 1,
        },
      ],
      buttons: [
        {
          text: this.i18n.t("Cancel"),
          role: "cancel",
        },
        {
          text: this.i18n.t("Confirm"),
          handler: (value) => {
            console.log("value", value);
            this.routineTime = value.hour.text + ':' + value.minute.value + ' ' + value.ampm.value;
             this.appState.set('routineTime',this.routineTime) 
              this.calenderCommit();
           
          },
        },
      ],
      cssClass: `${this.homeUIService.isDark() ? "dark-theme" : "light-theme"}`,
    });

    await picker.present();
   }
  
  generateHourOptions() {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      options.push({
        text: i.toString(),
        value: i.toString().padStart(2, "0"),
      });
    }
    return options;
  }
  generateColumnOptions() {
    const options = [
      { text: "00", value: "00" },
      { text: "30", value: "30" },
    ];
    return options;
  }

  notsureTimesDays(makeRoutine,value,type) {
     this.dialogRef.close(`${makeRoutine},${type},${value}`);

    this.appState.set('commitData',`${makeRoutine},${type},${value}`)
    localStorage.setItem('commitData', `${makeRoutine},${type},${value}`)
    
    this.appState.set('makeCommitment',makeRoutine)
    localStorage.setItem('makeCommitment', makeRoutine)

    if(this.isMobile){
      this.appState.set('isTimeEdited', false)
      this.dialogRef.close()  
    }
    else {
      this.appState.set('isTimeEdited', false)
      this.modalController.dismiss()
    }
  }
    
}
