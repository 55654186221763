import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  HostListener,
  ChangeDetectorRef
} from "@angular/core";
import { AppState } from "../app.service";
import { HabitService } from "../habit/habit.service";
import { HabitListItem } from "./habit-list-modal.model";
import { Path } from "../path/path.model";
import { User } from "../user/user.model";
import { ToastrService } from "ngx-toastr";
import { I18n } from "../i18n.service";
import * as moment from "moment";
import { TimePickerComponent } from "../time-picker/time-picker.component";
import { CordovaService } from "../cordova.service";
import * as AddToCalendar from "../../../vendor/add-to-calendar-buttons/add-to-calendar.js";
import { Router } from "@angular/router";
import { DailyStepsService } from "../daily-steps/daily-steps.service";
import { HomeV5Service } from "../pages/home-v5/home-v5.service";
import { RoutineService } from "../daily-content-v2/routine.service";
import { Habit } from "../habit/habit.model";
import { AlertWebCalendarComponent } from "../alert-web-calendar/alert-web-calendar.component";
import { MatDialog } from "@angular/material/dialog";
import { RatingAlertBoxComponent } from "../rating-alert-box/rating-alert-box.component";
import { AlertRatingRoutineComponent } from "../alert-rating-routine/alert-rating-routine.component";
import { AlertIamInComponent } from "../alert-iam-in/alert-iam-in.component";
import { AlertWebactionCalendarComponent } from "../alert-webaction-calendar/alert-webaction-calendar.component";
import { CalendarService } from "../calendar.service";
import { ModalController } from "@ionic/angular";
import { CompletedReviewModalComponent } from "../completed-review-modal/completed-review-modal.component";

declare var $: any;

export interface RoutineListItem {
  id: number,
  title: string,
  routineRepLogs: any[]
}

export interface CalendarParams {
  title: String;
  startDate: Date;
  endDate: Date;
  eventLocation: String;
  notes: String;
  endEventTime: any;
  frequency: string
}

@Component({
  selector: "app-habit-list-modal",
  templateUrl: "./habit-list-modal.component.html",
  styleUrls: ["./habit-list-modal.component.scss"]
})
export class HabitListModalComponent implements OnInit, AfterViewInit {
  @ViewChild("modalActionList") modalActionList: ElementRef;
  @Input() "visible": boolean;
  @ViewChild("timePicker") timePicker: TimePickerComponent;
  @ViewChild("calendarButtonsContainer") calendarButtonsContainer: ElementRef;
  @ViewChild("modalSnoozed") modalSnoozed: ElementRef;

  currentPath: Path;
  currentUser: User;
  habits: HabitListItem[];
  loading: boolean;
  calendarParams: CalendarParams;
  confirmedTime: string;
  currentDay: number;
  hasNativeCalendar: boolean; // LUMBA-1421
  cloudCalendarExpanded: boolean; // LUMBA-1421
  cloudCalendarSet: boolean; // LUMBA-1421
  //modalSnoozedHabit: SnoozedHabit;
  modalSnoozedHabit = false;
  isAllActionList: boolean
  public toggleButtonValue;
  isRoutine: boolean = true
  routineData: RoutineListItem[];
  habit: Habit
  recurrenceInterval: string
  routineList: any;
  isUpdated: boolean;
  isScheduleLater;
  isMobile: boolean;
  homeUIService: any;

  constructor(
    private appState: AppState,
    private i18n: I18n,
    private habitService: HabitService,
    private toastrService: ToastrService,
    private dailyStepsService: DailyStepsService,
    private cordovaService: CordovaService,
    private router: Router,
    private homeService: HomeV5Service,
    private dialog: MatDialog,
    private calendarService: CalendarService,
    //private tostrService:ToastrService,
    private cdRef: ChangeDetectorRef,
    private routineService: RoutineService,
    private modalCtrl: ModalController
    //private spinnerService: SpinnerService
  ) {
    this.isMobile = this.appState.get('isMobile');
    this.currentPath = this.appState.get("currentPath");
    this.currentUser = this.appState.get("currentUser");
    this.currentDay = this.appState.get("currentScheduleDay");
    const w: any = window;
    this.hasNativeCalendar =
      this.cordovaService.onCordova && w && w.plugins && w.plugins.calendar;
    this.routineList = this.appState.get('routines')

    this.getRoutineList()
  }


  ngOnInit() {
    //this.setSnoozedHabits();
    this.toggleButtonValue = "routines";
    this.isRoutine = true;
    if (this.isAllActionList) {
      this.setActionsList()
    }
    this.habit = this.findForCurrentDay(this.currentPath.habits)
    if (this.isRoutine) {
      this.getRoutineList()
      this.setActionsList()
    }
    this.isUpdated = this.appState.get('isUpdatedCommitment')
    console.log('isUpdatedCommitment', this.isUpdated)
    if (this.isUpdated) {
      this.appState.set('isUpdatedCommitment', false)
      this.refresh()
    }

    this.isScheduleLater = this.currentPath.habits.filter(item => item.scheduledLater)
    console.log('isScheduleLater', this.isScheduleLater)
  }

  setActionsList() {
    this.habits = new Array(this.currentPath.daysOfContent)
      .fill(0)
      .map((value, index) => {
        const dayNum = index + 1;
        const habit = this.currentPath.habits.find(
          item => item.startDay <= dayNum && dayNum <= item.endDay
        );
        if (habit) {
          return {
            id: habit.id,
            dayNum: habit.startDay, // LUMBA-1307
            title: habit.title,
            text: habit.text,
            casualDocumentId: habit?.casualDocumentId,
            documents: habit?.documents,
            scheduledLater: habit.confirmation < 1 && habit.scheduledLater > 0,
            confirmed: habit.confirmation > 0,
            isConvertedToRoutine: habit.isConvertedToRoutine,
            isCompleted: habit.isCompleted,
            screenId: "daily-content-screen-habit"
          };
        }
        return {
          id: null,
          dayNum: dayNum,
          title: null,
          text: null,
          casualDocumentId:null,
          documents: null,
          scheduledLater: false,
          confirmed: false,
          isConvertedToRoutine: false,
          isCompleted: false,
          screenId: ""
        };
      });

    this.habits.filter(
      item => item.isCompleted !== true
    )

    console.log("Habits ", this.habits);

  }

  addCalendarEvent() {
    // this.notificationService.confirm('Add events to you calendar?')
    this.createCalendarEvent();
  }

  createCalendarEvent() {
    const success = function (message) {
      console.log("Success: " + JSON.stringify(message));
    };
    const error = function (message) {
      console.error("Error: " + message);
    };

    // create an event interactively
    const w: any = window;
    const calOptions = w.plugins.calendar.getCalendarOptions();
    const daysCount = this.currentPath.daysOfContent * 2; // LUMBA-1399
    calOptions.recurrence = "daily";
    calOptions.recurrenceInterval = 1;
    calOptions.firstReminderMinutes = 0;
    calOptions.recurrenceEndDate = moment(this.confirmedTime, "HH:mm")
      .add(daysCount, "day")
      .toDate();
    // w.plugins.calendar.createEventInteractivelyWithOptions(
    //   this.calendarParams.title,
    //   this.calendarParams.eventLocation,
    //   this.calendarParams.notes,
    //   this.calendarParams.startDate,
    //   this.calendarParams.endDate,
    //   calOptions,
    //   success,
    //   error
    // );

    this.calendarService.scheduleEvents(
      this.calendarParams.title,
      this.calendarParams.eventLocation,
      this.calendarParams.notes,
      this.calendarParams.startDate,
      this.calendarParams.endDate,
      calOptions,
      success,
      error
    );
  }

  confirmTime() {
    this.confirmedTime = this.timePicker.getTime();
  }

  showModal() {
    $(this.modalActionList.nativeElement).modal("show");
  }

  toggleSnoozHabit() {
    this.isRoutine = true
    this.toggleButtonValue = "routines";
    this.setActionsList();
    this.getRoutineList()
  }


  toggleAllHabit() {
    this.isAllActionList = true;
    this.isRoutine = false
    this.toggleButtonValue = "allHabit";
    this.setActionsList();
  }

  emailList() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.habitService.sendPdf().subscribe(
      () => {
        this.toastrService.success(this.i18n.t("PDF sent!"));
        this.loading = false;
      },
      () => {
        this.toastrService.success(this.i18n.t("Error"));
        this.loading = false;
      }
    );
  }

  initCalendarButton() {
    this.prepareCalendarParams();
    if (this.hasNativeCalendar) {
      return;
    }
    const calendarButtons = AddToCalendar.addToCalendar({
      options: {
        class: "mtr-btn",
        id: "add-calendar-buttons-id-1"
      },
      data: {
        title: this.calendarParams.title,
        start: this.calendarParams.startDate,
        // timezone: America/Los_Angeles,					// converts the time to the IANA timezone
        end: this.calendarParams.endDate,
        // duration: 120,                            // Event duration (IN MINUTES)
        // allday: true,													// Override end time, duration and timezone, triggers 'all day'
        address: this.calendarParams.eventLocation,
        description: this.calendarParams.notes
      }
    });
    const parent = $(this.calendarButtonsContainer.nativeElement);
    const w: any = window;
    w.closeCalenderOnMouseDown = this.cloudCalendarCollapser.bind(this);
    parent.empty();
    parent.append(calendarButtons);
    $("#checkbox-for-add-calendar-buttons-id").change(
      event => (this.cloudCalendarExpanded = event.target.checked)
    );
  }

  cloudCalendarCollapser() {
    const doCollapseCloudCalendar = () => {
      setTimeout(() => {
        $("#checkbox-for-add-calendar-buttons-id")[0].checked = false;
      }, 150);
      this.cloudCalendarExpanded = false;
      this.cloudCalendarSet = true;
      document.removeEventListener("mousedown", doCollapseCloudCalendar);
    };
    document.addEventListener("mousedown", doCollapseCloudCalendar);
  }
  

  prepareCalendarParams(title: any = '', msg: String = '', routinelen: any = null, eventlen: string = null) {
    const start = moment(new Date(), 'HH:mm')
    const end = moment(new Date(), 'HH:mm').add(10, 'minute')
    this.calendarParams = {
      startDate: start.toDate(),
      endDate: end.toDate(),
      title: title, //updated title
      eventLocation: '',
      notes: msg,
      endEventTime: routinelen,
      frequency: eventlen
    }
  }

  setSnoozedHabits() {
    this.currentPath.habits.sort((a, b) => a.endDay - b.endDay);
    this.dailyStepsService.setSnoozedSteps(
      this.currentPath.habits
        .filter(
          item =>
            item.scheduledLater &&
            // hided the this condition which show the snooz action above the completed day
            // item.endDay < this.currentDay &&
            item.confirmation < 1
        ) // LUMBA-1360, // LUMBA-1445
        .map(habit => {
          return {
            id: habit.id,
            dayNum: habit.startDay, // LUMBA-1307
            title: habit.getTitle(),
            text: habit.text,
            actionPoints: habit.actionPoints,
            screenId: "daily-content-screen-habit"
          };
        })
    );
  }

  getSnoozedSteps() {
    return this.dailyStepsService.getSnoozedSteps();
  }

  removeFromSnoozed(habit_id) {
    this.dailyStepsService.removeFromSnoozed(habit_id);
  }

  markCompleted(habit_id: number) {
    var habit = this.findHabitbyId(this.currentPath.habits, habit_id);
    this.habitService.confirm(habit_id).subscribe(
      (response) => {
        console.log("Habit confirm Response ", response);

      },
      (error) => {
        console.log("Habit confirm error ", error);
      },
      () => {
        localStorage.setItem(
          `Toggle:User:${this.currentUser.id}:HabitConfirmed:${habit_id}`,
          "yes"
        );
        localStorage.removeItem(
          `Toggle:User:${this.currentUser.id}:HabitScheduled:${habit_id}`
        );
        this.removeFromSnoozed(habit_id);
        $(this.modalSnoozed.nativeElement).modal("hide");
      }
    );
    this.toastrService.success(this.i18n.t("Action Completed..!"));
    // To reload the All Actions list 
    this.setActionsList()

    try {
      if (this.currentPath.enabledAttributes.feedback) {
        const dialogRef = this.dialog.open(RatingAlertBoxComponent, {
          data: habit,
        });
        dialogRef.afterClosed().subscribe((result) => {

          if (result == 'confirm') {

            const requestData = {
              'user_id': this.currentUser.id,
              'habit_id': habit_id,
              'rating': JSON.parse(localStorage.getItem('rating1')),
              'bizrating': JSON.parse(localStorage.getItem('rating2')),
              'rcomment': localStorage.getItem('postText')
            }

            if (requestData.rcomment == '' && requestData.bizrating == 0 && requestData.rating == 0) {
              return true;
            } else {
              this.homeService.giveFeedback(requestData).subscribe(
                (response) => {
                  //this.tostrService.success('Action Completed!')

                },
                (error) => {
                  console.log('Error for feedback ', error)
                }
              )
            }
          }
        })

      }
    } catch (o_O) {
      console.log(o_O);
    }

  }

  @HostListener("click", ["$event"])
  onLinkClick(e) {
    if (
      e.target.tagName.toLowerCase() === "a" &&
      e.target.target === "_blank"
    ) {
      if (this.cordovaService.onCordova) {
        console.log("cordova click");
        e.stopPropagation();
        e.preventDefault();
        this.cordovaService.openLinkInBrowser(e.target.href);
      } else {
        window.open(e.target.href, "_blank");
      }
    }
  }

  openSnoozedContent(step) {
    //this.modalSnoozedHabit = step;
    $(this.modalSnoozed.nativeElement).modal("show");
  }

  ngAfterViewInit() {
    // $(this.modalActionList.nativeElement).on('shown.bs.modal', () => {
    //   $('.modal-backdrop').click(() => {
    //     $(this.modalActionList.nativeElement).modal("hide");
    //   }); // LUMBA-1426
    // });

    // $(this.modalSnoozed.nativeElement).on("shown.bs.modal", () => {
    //   $(".modal-backdrop").click(() => {
    //     $(this.modalSnoozed.nativeElement).modal("hide");
    //   }); // LUMBA-1426
    // });
  }

  getRoutineList() {
    console.log('currentPath.id', this.currentPath.id)
    console.log('currentUser.id', this.currentUser.id)
    const requestData = {
      'user_id': this.currentUser.id,
      'path_id': this.currentPath.id
    }

    if (requestData.user_id == 0 && requestData.path_id == 0) {
      return true;
    } else {
      this.routineService.getRoutineList(requestData).subscribe(
        (response) => {
          this.routineList = response.data

          this.cdRef.detectChanges();
        },
        (error) => {
          console.log('Error for routines ', error)
        }

      )
    }

  }


  logaRep(routineId) {
    const dialogRef = this.dialog.open(AlertRatingRoutineComponent)
    dialogRef.afterClosed().subscribe((result) => {

      console.log('routineId', routineId)
      if (result == 'confirm') {
        //this.spinnerService.on()
        const requestData = {
          'routine_id': routineId,
          'routine_rating_second': JSON.parse(localStorage.getItem('routineRating2')),
          'routine_rating_first': JSON.parse(localStorage.getItem('routineRating1')),
          'routine_comment': localStorage.getItem('feedbackText')
        }

        this.routineService.giveFeedback(requestData).subscribe(
          (response) => {
            // this.tostrService.success('Rep Completed!')

            this.getRoutineList()
            this.cdRef.detectChanges()
            // this.spinnerService.off()
          },
          (error) => {
            // this.tostrService.error('Rep Not Completed!')
            // this.spinnerService.off()
            console.log('Error for feedback ', error)
          }
        )

      }
    })
  }

  async markAsDone(habit) {
    console.log('habit', habit);
    let currentHabit = this.findHabitbyId(this.currentPath.habits, habit.id)
    try {
      this.dailyStepsService.removeFromSnoozed(currentHabit.id)
      localStorage.setItem(
        `Toggle:User:${this.currentUser.id}:HabitConfirmed:${currentHabit.id}`,
        'yes'
      )
      localStorage.removeItem(
        `Toggle:User:${this.currentUser.id}:HabitScheduled:${currentHabit.id}`
      )

      var actionPoints = currentHabit.actionPoints

      this.habitService.confirm(currentHabit.id).subscribe(res => {
        // LUMBA-1344
        this.cdRef.detectChanges()

        if (actionPoints > 0) {
          this.appState.set('isCompletedAction', true)
        }
      })
    } catch (o_0) {
      console.log('Errors ', o_0)
    }

    console.log("Log data", currentHabit);

    try {
      if (this.currentPath.enabledAttributes.feedback && currentHabit.isCompleted) {
        await this.initModal(habit.dayNum, currentHabit)
      } else {
        this.checkIsRoutine(currentHabit)
      }

    } catch (o_O) {
      console.log(o_O);
    }
  }

  async initModal(dayNum, currentHabit:any) {
    // this.appState.set('currentDay', dayNum);
    console.log('currentHabit', currentHabit);
    const modal = await this.modalCtrl.create({
      component: CompletedReviewModalComponent,
       componentProps: {currentHabit: currentHabit},
      cssClass: this.homeUIService.isDark() ? 'dark-theme-review-modal mobile-custom-to_do_list-forms' : 'light-theme mobile-custom-to_do_list-forms',
    });

    modal.onDidDismiss().then((modalDataResponse) => {
      if (modalDataResponse !== null) {
        this.setActionsList();
        this.cdRef.detectChanges();
        if (currentHabit.isroutine) {
          console.log('isroutine', currentHabit.isroutine);
          this.openImInDialog(currentHabit)
        } else {
          this.setActionsList()
        }
      }
    });
    return await modal.present();
  }

  openImInDialog(habit) {
    const dialogImIn = this.dialog.open(AlertIamInComponent)
    dialogImIn.afterClosed().subscribe((result) => {
      // tslint:disable-next-line: triple-equals
      if (result == 'confirm') {
        //"I'M IN Stuff"
        //1. Create a new routine from existing habit
        //2. Create a calendar event 
        // this.spinnerService.on()
        this.prepareCalendarParams("1st90 Habit: " + habit.title, this.innerHtmlToRoutinesData(habit.text), habit.routinelen, habit.eventlen)
        if (this.hasNativeCalendar) {
          this.createRoutineEvent(habit)
        } else {
          this.homeService.setCalendarData(this.calendarParams)
          const dialogRef = this.dialog.open(AlertWebCalendarComponent)
          dialogRef.afterClosed().subscribe((result) => {
          })
        }

        this.routineService.createNewRoutine(habit.id, this.currentPath.id, this.currentUser.id).subscribe(
          (response) => {
            console.log('Response for routine ', response)
            let status = response.status
            if (status === true) {
              //   this.tostrService.success("Routine created!")
              this.appState.get('currentPath').update('habits', habit.id, { isConvertedToRoutine: true })
              this.getRoutineList();
              this.setActionsList();
              this.cdRef.detectChanges()
              //  this.spinnerService.off()
            }
            else {
              this.getRoutineList();
              this.setActionsList();
              this.cdRef.detectChanges()
              //  this.spinnerService.off()
            }
          },
          (error) => {
            console.log('Error for feedback ', error)
          }
        )
      }
    })
  }

  findLastHabit(collection: any, lastDay: any): any {
    return collection.find(
      item => item.startDay <= lastDay && lastDay <= item.endDay
    )
  }

  findHabitbyId(collection: any, id: any): any {
    return collection.find(
      item => item.id === id
    )
  }

  findForCurrentDay(collection: any): any {
    return collection.find(
      item => item.startDay <= this.currentDay && this.currentDay <= item.endDay
    )
  }

  checkIsRoutine(habit) {
    if (habit.isroutine) {
      this.openImInDialog(habit)
    } else {
      // this.tostrService.success('Action Completed!')
      this.getRoutineList();
      this.setActionsList();
      this.cdRef.detectChanges()
    }
  }

  innerHtmlToNotesData(habit: string) {

    var arrayOfUrls = []
    var divHabitData = document.createElement("DIV");
    divHabitData.innerHTML = habit;
    let cleanText = divHabitData.innerText;

    var uri_pattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    arrayOfUrls = habit.match(uri_pattern);

    console.log("String of links ", cleanText + "\n\n" + arrayOfUrls);
    return cleanText + "\n\n Here is the tool you saw in the 1st90 app to help you complete this action: " + arrayOfUrls + "\n\n To mark this action complete in the 1st90 app, tap the bell icon at the top of your screen and select this action from the list. Tap here on mobile to open the 1st90 app: https://stage1st90.page.link/n3UL";
  }

  innerHtmlToRoutinesData(habit: string) {

    var arrayOfUrls = []
    var divHabitData = document.createElement("DIV");
    divHabitData.innerHTML = habit;
    let cleanText = divHabitData.innerText;

    var uri_pattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    arrayOfUrls = habit.match(uri_pattern);

    console.log("String of links ", cleanText + "\n\n" + arrayOfUrls);
    return cleanText + "\n\n After you have completed this action, mark it complete in the 1st90 app by clicking here: https://stage1st90.page.link/d6o5";
  }


  innerHtmlToWebAction(habit: string) {

    var arrayOfUrls = []
    var divHabitData = document.createElement("DIV");
    divHabitData.innerHTML = habit;
    let cleanText = divHabitData.innerText;

    var uri_pattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    arrayOfUrls = habit.match(uri_pattern);

    console.log("String of links ", cleanText + "\n\n" + arrayOfUrls);
    return cleanText + "\n\n After you have completed this action, mark it complete in the 1st90 app by clicking here: https://stage1st90.page.link/d6o5";
  }

  createRoutineEvent(habit) {
    const success = function (message) { console.log('Success: ' + JSON.stringify(message)) }
    const error = function (message) { console.error('Error: ' + message) }
    this.recurrenceInterval = ''
    try {
      if (typeof habit.eventlen === 'string') {
        this.recurrenceInterval = habit.eventlen
      }
    } catch (o_0) {

    }

    // create an event interactively
    const w: any = window
    const calOptions = w.plugins.calendar.getCalendarOptions()
    const calendarList = w.plugins.calendar.listCalendars(success, error);

    console.log("Calendar List ", calendarList);

    const daysCount = habit.routinelen  // LUMBA-1399
    calOptions.recurrence = this.recurrenceInterval.toLowerCase(); // supported are: daily, weekly, monthly, yearly
    calOptions.firstReminderMinutes = 0
    calOptions.recurrenceEndDate = moment(new Date(), 'HH:mm').add(daysCount, 'day').toDate()
    this.calendarService.scheduleEvents(
      this.calendarParams.title,
      this.calendarParams.eventLocation,
      this.calendarParams.notes,
      this.calendarParams.startDate,
      this.calendarParams.endDate,
      calOptions,
      success,
      error
    );
  }

  async setReminder(habit) {
    var habit = this.findHabitbyId(this.currentPath.habits, habit.id)
    let posts, docLink;
    habit?.documents?.forEach((doc)=>{
      if(doc?.id == habit?.casualDocumentId) {
        posts =doc;
      }
    })
    let doc = this.appState.get('singleDocument');
    if(posts?.id == doc?.id) {
      posts =this.appState.get('singleDocument')
    }
    
    this.appState.set('singleDocument', posts)
    docLink= posts?.editedDocumentUrl?posts?.editedDocumentUrl:posts?.link;
    console.log('habitData', habit)
    this.prepareCalendarParams("1st90 Action : " + habit.title, this.routineService.innerHtmlToMobileAction(habit.text,docLink))
    if (this.hasNativeCalendar) {
      this.createRoutineEvent(habit)
    } else {
      this.calendarParams.notes = this.routineService.innerHtmlToWebAction(habit.text,docLink)

      this.homeService.setCalendarData(this.calendarParams)
      // const dialogRef = this.dialog.open(AlertWebactionCalendarComponent)
      // dialogRef.afterClosed().subscribe((result) => {
      // })
      const modal = await this.modalCtrl.create({
        component: AlertWebactionCalendarComponent,
        animated: false,
        cssClass: 'custom-modal-size',
       // enterAnimation: this.animationService.slideInLeftEnterAnimation,
        //leaveAnimation:this.animationService.SlideOutRightLeaveAnimation
      });
       await modal.present() 
    }
  }

  refresh() {
    return new Promise(resolve => {
      this.appState.refreshMainComponent.emit()
      setTimeout(resolve, 0)
    })
  }

  public getColor(routine) {
    var array = JSON.parse(routine.pathcolours)
    return array.theme
  }

  getCommitmentsCheck(isRoutine, isScheduleLater, routineList): boolean {
    try {
      if (isRoutine && routineList && routineList.length === 0 && isScheduleLater && isScheduleLater.length === 0) {
        return true
      } else {
        return false
      }
    } catch (error) {
      return true
    }

  }

}
