import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

import * as query from './routine.query'
import { AppState } from '../app.service'
import { Filter } from '../shared/filter'
import { Routine } from './routine.model'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Path } from '../path/path.model'
import { User } from '../user/user.model'
import * as moment from 'moment'
import { CordovaService } from '../cordova.service'
import { CalendarService } from '../calendar.service'
import { DomSanitizer } from '@angular/platform-browser'
import { NativeService } from '../services/native.service'

export interface CalendarParams {
  title: String,
  startDate: Date
  endDate: Date,
  eventLocation: String,
  notes: String
}

export interface Routines {
  [routine: string]: any
}

export interface EndRoutine {
  [endRoutine: string]: any
}


@Injectable()
export class RoutineService {

  API_URL: string = environment.apiBaseUrl
  feedback = '/users/rate_routine'
  routineList: any
  endRoutineList = '/routineAllData'
  routines = '/routineList'
  userDeatils = '/user_data'
  todos = '/getToDo'

  routinesData: any

  currentPath: Path
  currentUser: User
  recurrenceInterval: string
  calendarParams: CalendarParams
  hasNativeCalendar: boolean

  createRoutine: Routines
  routineEnd: EndRoutine
  presignedUrl;

  constructor(
    private apollo: Apollo,
    private appState: AppState,
    private cordovaService: CordovaService,
    private sanitizer: DomSanitizer,
    private nativeService: NativeService,
    private calendarService: CalendarService,
    private httpclient: HttpClient) {
    const w: any = window
    this.hasNativeCalendar = this.cordovaService.onCordova && w && w.plugins && w.plugins.calendar
    this.presignedUrl = environment.preSignedUrl

    this.init()
  }

  init() {
    this.currentUser = this.appState.get('currentUser')
    this.currentPath = this.appState.get('currentPath')
  }

  createNewRoutine(habitId: number, pathId: number, userId: number) {
    return this.apollo.mutate({
      mutation: query.routines,
      variables: {
        habitId: habitId,
        pathId: pathId,
        userId: userId
      }
    }).pipe(
      map(response => {
        this.createRoutine = response.data
        return this.createRoutine.routines
      })
    )
  }


  public giveFeedback(data): Observable<any> {
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.httpclient.post(this.API_URL + this.feedback, data,{headers})
  }

  endRoutine(id: number, pathId: number, userId: number, habitId: number) {
    return this.apollo.mutate({
      mutation: query.endRoutine,
      variables: {
        id: id,
        pathId: pathId,
        userId: userId,
        habitId: habitId
      }
    }).pipe(
      map(response => {
        this.routineEnd = response.data
        return this.routineEnd.routines
      })
    )
  }


  public getRoutineList(data): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Authorization', localStorage.getItem('token'));
    const params = new HttpParams().append('user_id', data.user_id)
      .append('path_id', data.path_id);
    return this.httpclient.get(this.API_URL + this.routines, { headers, params });
  }
  public getUserDetailsById(id: string): Observable<any> {
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams().append('id', id);
    return this.httpclient.get(this.API_URL + this.userDeatils, { headers, params });
  }

  public getListRoutines(data): Observable<any> {
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams().append('user_id', data.user_id)
      .append('path_id', data.path_id);
    return this.httpclient.get(this.API_URL + this.endRoutineList, { headers, params });
  }

  public getList(userId, pathId) {
    const requestData = {
      'user_id': userId,
      'path_id': pathId
    }

    if (requestData.user_id !== 0 && requestData.path_id !== 0) {

      this.getRoutineList(requestData).subscribe(
        (response) => {
          this.routineList = response.data
          this.appState.set("routines", this.routineList)
        },
        (error) => {
          console.log('Error for routines ', error)
        }

      )
    }
  }


  findHabitbyId(collection: any, id: any): any {
    return collection.find(
      item => item.id === id
    )
  }


  prepareCalendarParams(title: any, msg: String) {
    const start = moment(new Date(), 'HH:mm')
    const end = moment(new Date(), 'HH:mm').add(10, 'minute')
    this.calendarParams = {
      startDate: start.toDate(),
      endDate: end.toDate(),
      title: title, //updated title
      eventLocation: '',
      notes: msg
    }
  }


  innerHtmlToRoutinesData(habit: string) {

    var arrayOfUrls = []
    var divHabitData = document.createElement("DIV");
    divHabitData.innerHTML = habit;
    let cleanText = divHabitData.innerText;

    var uri_pattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    arrayOfUrls = habit.match(uri_pattern);

    console.log("String of links ", cleanText + "\n\n" + arrayOfUrls);
    return cleanText + "\n\n After you have completed this action, mark it complete in the 1st90 app by clicking here: https://stage1st90.page.link/d6o5";
  }

  createRoutineEvent(habit) {
    const success = function (message) { console.log('Success: ' + JSON.stringify(message)) }
    const error = function (message) { console.error('Error: ' + message) }
    this.recurrenceInterval = ''
    try {
      if (typeof habit.eventlen === 'string') {
        this.recurrenceInterval = habit.eventlen
      }
    } catch (o_0) {

    }

    // create an event interactively
    const w: any = window
    const calOptions = w.plugins.calendar.getCalendarOptions()
    const calendarList = w.plugins.calendar.listCalendars(success, error);

    console.log("Calendar List ", calendarList);

    const daysCount = habit.routinelen  // LUMBA-1399
    calOptions.recurrence = this.recurrenceInterval.toLowerCase(); // supported are: daily, weekly, monthly, yearly
    calOptions.firstReminderMinutes = 0
    calOptions.recurrenceEndDate = moment(new Date(), 'HH:mm').add(daysCount, 'day').toDate()
    this.calendarService.scheduleEvents(
      this.calendarParams.title,
      this.calendarParams.eventLocation,
      this.calendarParams.notes,
      this.calendarParams.startDate,
      this.calendarParams.endDate,
      calOptions,
      success,
      error
    );
  }


  setReminder(habit) {
    var habit = this.findHabitbyId(this.currentPath.habits, habit.id)
    console.log('habitData', habit)
    this.createCalendarRoutine(habit)
  }

  createCalendarRoutine(habit) {
    if (this.hasNativeCalendar) {
      this.prepareCalendarParams("1st90 Habit: " + habit.title, this.innerHtmlToRoutinesData(habit.text))
      this.createRoutineEvent(habit)
    }
  }

  public getToDoList(id: number): Observable<any> {
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams().append('path_id', id);
    return this.httpclient.get(this.API_URL + this.todos, { headers, params });
  }
  innerHtmlToWebAction(habit: any,link) {
    const currentDate = new Date();
    // Add 7 days to the current date
    let date = new Date(currentDate.setDate(currentDate.getDate() + 7));
    const formattedDate = this.nativeService.formatDate(date);
    let url;
    let isPresignedURL = link?.includes(this.presignedUrl)
    if(isPresignedURL) {
      link =encodeURIComponent(link);
      url = this.sanitizer.bypassSecurityTrustUrl(link);
    }
    else {
      url =link;
    }
    var arrayOfUrls = []
    var divHabitData = document.createElement("DIV");
    divHabitData.innerHTML = habit;
    let cleanText = divHabitData.innerText;

    var uri_pattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    arrayOfUrls = habit.match(uri_pattern);
    if(isPresignedURL) {
      return cleanText + "\n\n Here is the tool you saw in the 1st90 app to help you complete this action:( " +url.changingThisBreaksApplicationSecurity + ").\n\n This link will expire on" + formattedDate +". To see and edit this tool any time, open your Library in the app"+"\n\n After you have completed this action, mark it complete in the 1st90 app by clicking here: https://stage1st90.page.link/d6o5";
      }
    else if(url || arrayOfUrls ) {
        link=link?link:arrayOfUrls;
        return cleanText + "\n\n Here is the tool you saw in the 1st90 app to help you complete this action: " + link + "\n\n . To see and edit this tool any time, open your Library in the app" + "\n\n After you have completed this action, mark it complete in the 1st90 app by clicking here: https://stage1st90.page.link/d6o5";
    }
    else {
      return cleanText + "\n\n After you have completed this action, mark it complete in the 1st90 app by clicking here: https://stage1st90.page.link/d6o5";
    }
  }

  innerHtmlToMobileAction(habit: string,link) {
     
    const currentDate = new Date();
    // Add 7 days to the current date
    let date = new Date(currentDate.setDate(currentDate.getDate() + 7));
    const formattedDate = this.nativeService.formatDate(date);
    var arrayOfUrls = []
    let isPresignedURL = link?.includes(this.presignedUrl)
    var divHabitData = document.createElement("DIV");
    divHabitData.innerHTML = habit;
    let cleanText = divHabitData.innerText;

    var uri_pattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    arrayOfUrls = habit.match(uri_pattern);
    if(isPresignedURL) {
      return cleanText + "\n\n Here is the tool you saw in the 1st90 app to help you complete this action:( " +link + ").\n\n This link will expire on" + formattedDate +". To see and edit this tool any time, open your Library in the app"+"\n\n After you have completed this action, mark it complete in the 1st90 app by clicking here: https://stage1st90.page.link/d6o5";
      }
    else if(link || arrayOfUrls ) {
      link=link?link:arrayOfUrls;
      return cleanText + "\n\n Here is the tool you saw in the 1st90 app to help you complete this action: " + link + "\n\n . To see and edit this tool any time, open your Library in the app" + "\n\n After you have completed this action, mark it complete in the 1st90 app by clicking here: https://stage1st90.page.link/d6o5";
    }
    else {
      return cleanText + "\n\n After you have completed this action, mark it complete in the 1st90 app by clicking here: https://stage1st90.page.link/d6o5";
    }
  }
  getTodoList(path_id: any) {
    if (!path_id) {
      return true;
    } else {
      this.getToDoList(path_id).subscribe(
        (response) => {
          this.appState.set('toDos', response.data)
        },
        (error) => {
          console.log('Error for todos ', error)
        }

      )
    }
  }

}
